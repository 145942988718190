import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'

function NotFoundPage({ data: { site } }) {
  return (
    <Layout title="404" subtitle="404" site={site}>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      ...SiteInformation
    }
  }
`
